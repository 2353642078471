/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

'use strict'

//1. Preload page
initPageLoader()

$(document).ready(function ($) {
  //2. Lazy loading
  const el = document.querySelectorAll('[data-lazy-load]')
  const observer = lozad(el, {
    loaded: function (el) {
      // Custom implementation on a loaded element
      el.parentNode.classList.add('loaded')
    },
  })

  observer.observe()

  initPolicy()

  initCustomSweetAlert()

  //5. Init Layout
  initNavbar()
  initMobileMenu()
  initBackgroundImages()

  //6. Components
  initTooltips()

  //7. Carousels

  initHomeCarousel()

  //10. Demo
  initParallax()
  initBackToTop()

  //11. Utility functions
  initAnchorScroll()
  initScrollReveal()

  //12. Page specific methods
  initPageAnimations()
  initContactForm()

  initContactBudget()
})
