/* ==========================================================================
Contact
========================================================================== */

'use strict'

function initContactForm() {
  function clearForm() {
    $('#contact #name').val('')
    $('#contact #phone').val('')
    $('#contact #email').val('')
    $('#contact #message').val('')

    $('#contact #contact-error').css('display', 'none')

    $("#contact form button[type='submit']").removeClass('is-loading')
  }

  // Init Input Mask
  $('#contact form #phone').inputmask({
    mask: '(99) 9999[9]-9999',
    removeMaskOnSubmit: true,
  })

  // Form submit
  $('#contact form').on('submit', function (evt) {
    evt.preventDefault()

    let name = $('#contact #name').val()
    let phone = $('#contact #phone').val()
    let email = $('#contact #email').val()
    let message = $('#contact #message').val()

    let linkWhatsapp = $('#whatsapp-button').prop('href')

    window.open(`${linkWhatsapp}?text=${message}`, '_blank')

    $.ajax({
      url: '/api/send-contact',
      method: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({
        name,
        phone,
        email,
        message,
      }),
      beforeSend: function () {
        $("#contact form button[type='submit']").addClass('is-loading')
      },
      complete: function () {
        clearForm()

        setTimeout(function () {
          SwalSuccess.fire({
            title: 'Sucesso!',
            text: 'Sua mensagem foi recebida, logo retornaremos!',
          })
        }, 500)
      },
    })
  })
}
