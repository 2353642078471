/* ==========================================================================
Modal
========================================================================== */

'use strict'

function initContactBudget() {
  function clearForm() {
    $('#modal-contact-budget #name').val('')
    $('#modal-contact-budget #phone').val('')
    $('#modal-contact-budget #email').val('')
    $('#modal-contact-budget #product').val('')
    $('#modal-contact-budget #plan').val('')

    $('#modal-contact-budget #contact-budget-error').css('display', 'none')

    $("#modal-contact-budget button[type='submit']").removeClass('is-loading')
  }

  // Init Input Mask
  $('#modal-contact-budget form #phone').inputmask('(99) 9999[9]-9999')

  //Triggering a modal
  $('.modal-contact-budget-trigger').on('click', function () {
    clearForm()

    $('#modal-contact-budget').toggleClass('is-active')
    $('#modal-contact-budget .modal-background').toggleClass('scaleInCircle')
    $('#modal-contact-budget .modal-content').toggleClass('scaleIn')
    $('#modal-contact-budget .modal-close').toggleClass('is-hidden')
    //Prevent sticky fixed nav and backtotop from overlapping modal
    $('#scrollnav, #backtotop').toggleClass('is-hidden')

    let product = $(this).attr('data-product')
    let plan = $(this).attr('data-plan')

    $('#modal-contact-budget form #product').val(product)
    $('#modal-contact-budget form #plan').val(plan)
  })

  //Closing a modal
  $(
    '.modal-close, .modal-dismiss, .modal-background',
    '#modal-contact-budget',
  ).on('click', function () {
    clearForm()

    $('#modal-contact-budget .modal-background').toggleClass('scaleInCircle')
    $('#modal-contact-budget .modal-content').toggleClass('scaleIn')
    $('#modal-contact-budget .modal-close').toggleClass('is-hidden')

    setTimeout(function () {
      $('.modal.is-active').removeClass('is-active')
      //Restore sticky nav and backktotop
      $('#scrollnav, #backtotop').toggleClass('is-hidden')
    }, 500)
  })

  // Form submit
  $('#modal-contact-budget form').on('submit', function (evt) {
    evt.preventDefault()

    let name = $('#modal-contact-budget form #name').val()
    let phone = $('#modal-contact-budget form #phone').val()
    let email = $('#modal-contact-budget form #email').val()
    let product = $('#modal-contact-budget form #product').val()
    let plan = $('#modal-contact-budget form #plan').val()

    let message = `Olá! Sou ${name} e gostaria de mais informações referentes ao sistema ${product}`
    if (plan) message += `, principalmente quanto ao plano ${plan}`

    let linkWhatsapp = $('#whatsapp-button').prop('href')

    window.open(`${linkWhatsapp}?text=${message}`, '_blank')

    $.ajax({
      url: '/api/send-contact-budget',
      method: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({
        name,
        phone,
        email,
        message,
      }),
      beforeSend: function () {
        $("#modal-contact-budget button[type='submit']").addClass('is-loading')
      },
      complete: function () {
        clearForm()

        $('#modal-contact-budget .modal-close').trigger('click')

        setTimeout(function () {
          SwalSuccess.fire({
            title: 'Sucesso!',
            text: 'Sua mensagem foi recebida, logo retornaremos!',
          })
        }, 500)
      },
    })
  })
}
