/* ==========================================================================
Carousel
========================================================================== */

'use strict'

function initHomeCarousel() {
  if ($('.home-carousel').length) {
    $('.home-carousel').slick({
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      pauseOnFocus: false,
      swipe: false,
      fade: true,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 1,
          },
        },
      ],
    })
  }
}
