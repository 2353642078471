/* ==========================================================================
Animations for pages
========================================================================== */

'use strict'

function initPageAnimations() {
  let offset = $(window).height() - 200

  if ($('.preFadeInRight').length) {
    $('.preFadeInRight').each(function (idx, element) {
      new Waypoint({
        element: element,
        handler: function (direction) {
          $(this.element).addClass('fadeInRight')
        },
        offset,
      })
    })
  }

  if ($('.preFadeInLeft').length) {
    $('.preFadeInLeft').each(function (idx, element) {
      new Waypoint({
        element: element,
        handler: function (direction) {
          $(this.element).addClass('fadeInLeft')
        },
        offset,
      })
    })
  }

  if ($('.preFadeInUp').length) {
    $('.preFadeInUp').each(function (idx, element) {
      new Waypoint({
        element: element,
        handler: function (direction) {
          $(this.element).addClass('fadeInUp')
        },
        offset,
      })
    })
  }

  if ($('#segments').length) {
    new Waypoint({
      element: $('#segments'),
      handler: function () {
        $('.segment-item').addClass('customFadeInUp')
      },
      offset: offset - 200,
    })
  }

  if ($('#products').length) {
    new Waypoint({
      element: $('#products'),
      handler: function () {
        $('.card-product').addClass('customFadeInUp')
      },
      offset: offset - 200,
    })
  }

  if ($('#contact').length) {
    new Waypoint({
      element: $('#contact'),
      handler: function () {
        $('.contact-card').addClass('customFadeInUp')
      },
      offset: offset - 200,
    })
  }

  if ($('.product-pricing').length) {
    new Waypoint({
      element: $('.product-pricing'),
      handler: function () {
        $('.header-pricing-card').addClass('customFadeInUp')
      },
      offset: offset - 200,
    })
  }

  if ($('.product-segments').length) {
    new Waypoint({
      element: $('.product-segments'),
      handler: function () {
        $('.square-icon-box', this.element).addClass('customFadeInUp')
      },
      offset: offset - 200,
    })
  }
}
