/* ==========================================================================
Popups
========================================================================== */

'use strict'

//Tooltips
function initTooltips() {
  if ($('[data-toggle="tooltip"]').length) {
    $('[data-toggle="tooltip"]').ggtooltip()
  }
}
