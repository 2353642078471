/* ==========================================================================
Custom SweetAlert2
========================================================================== */

'use strict'

function initCustomSweetAlert() {
  window.SwalSuccess = Swal.mixin({
    customClass: {
      confirmButton: 'button button-cta primary-btn no-lh is-bold raised',
    },
    icon: 'success',
    showCancelButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (box) => {
      box.addEventListener('mouseenter', Swal.stopTimer)
      box.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })
}
