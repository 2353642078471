/* ==========================================================================
Custom SweetAlert2
========================================================================== */

'use strict'

function initPolicy() {
  var cookieName = 'mastroti-cookie'

  function setCookie(e, o, i) {
    var t = ''
    if (i) {
      var n = new Date()
      n.setTime(n.getTime() + 24 * i * 60 * 60 * 1e3),
        (t = '; expires=' + n.toUTCString())
    }
    document.cookie = e + '=' + (o || '') + t + '; path=/'
  }

  function getCookie(e) {
    for (
      var o = e + '=', i = document.cookie.split(';'), t = 0;
      t < i.length;
      t++
    ) {
      for (var n = i[t]; ' ' == n.charAt(0); ) n = n.substring(1, n.length)
      if (0 == n.indexOf(o)) return n.substring(o.length, n.length)
    }
    return null
  }

  if (!getCookie(cookieName)) {
    $('#policy').addClass('init-animation')
  }

  $('#policy #confirm-policy').on('click', function (evt) {
    evt.preventDefault()

    setCookie(cookieName, 1, 1)

    $('#policy').removeClass('init-animation')
  })
}
